import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteName
					title
					description
					keywords
					image
					twitterUsername
					siteUrl
					og {
						type
						description
						title
					}
					currentYear
				}
			}
		}
	`);

	return data.site.siteMetadata;
};
