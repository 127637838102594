import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import './index.scss';

const IndexPage = () => {
	return <Layout pageClass="home-page" />;
};

export default IndexPage;

export const Head = () => <Seo />;
