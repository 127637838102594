import React from 'react';
import { useSiteMetadata } from '../hooks/use-site-metadata';

const Seo = ({ title, description, keywords, pathname, children }) => {
	const {
		title: defaultTitle,
		description: defaultDescription,
		keywords: defaultKeywords,
		image,
		siteUrl,
		og,
		twitterUsername,
	} = useSiteMetadata();

	const seo = {
		title: title ? `${title}` : defaultTitle,
		description: description || defaultDescription,
		keywords: keywords || defaultKeywords,
		image: `${siteUrl}${image}`,
		url: `${siteUrl}${pathname || ``}`,
		og: {
			type: og.type,
			description: og.description,
			title: og.title,
		},
		twitterUsername,
	};

	return (
		<>
			<title>{seo.title}</title>
			<meta name="description" content={seo.description} />
			<meta name="keywords" content={seo.keywords} />
			<meta name="image" content={seo.image} />
			<meta
				property="og:image"
				content="/static/website-social-card-44070c4a901df708aa1563ac4bbe595a.jpg"
			/>
			<meta property="og:url" content={seo.url} />
			<meta property="og:type" content={seo.og.type} />
			<meta property="og:title" content={seo.og.title} />
			<meta property="og:description" content={seo.og.description} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:url" content={seo.url} />
			<meta name="twitter:description" content={seo.description} />
			<meta name="twitter:image" content={seo.image} />
			{children}
		</>
	);
};

export default Seo;
